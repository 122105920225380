import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DataProtectionMeasures from "../components/data-protection-measures"
import Customers from "../components/customers"
import Testimonials from "../components/testimonials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import WhyCelantur from "../components/why-celantur"
import ActionForm from "../components/action-form"
import SuccessStoryCard from "../components/success-story-card"
import DeploymentOptions from "../components/deployment-options"
import MoreBlogPosts from "../components/more-blog-posts"

const IndexPage = ({ data }) => (
	<Layout>
		<SEO
			title="AI-Powered Image and Video Anonymization Software - Securely Protect Privacy with Celantur"
			description="Celantur software uses advanced AI and machine learning to automatically redact faces, license plates, persons, and vehicles on images and videos. Try it now for secure and efficient privacy protection."
		/>
		<section className="pt-4 pt-md-11 pb-10 pb-sm-14 bg-gradient">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 cold-md-5 col-lg-6 order-md-2">
						<video
							width="100%"
							height="100%"
							className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
							muted
							autoPlay
							loop
							playsInline
						>
							<source src="/videos/timesquare-small.mkv" type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
					<div className="col-12 cold-md-7 col-lg-6 order-md-1">
						<h1 className="display-4 text-center text-md-left font-weight-bold">
							Privacy-preserving
							<br />
							<span className="text-primary">Image and Video Blurring</span>
						</h1>
						<p className="lead text-center text-md-left mb-5">
							Automatically anonymize faces, license plates, persons and vehicles.

						</p>
						<p className="lead text-center text-md-left text-muted mb-5">
							Easy to use and integrate on all platforms:<br /> <a href="https://app.celantur.com/" target="_blank" className="event-start-demo">Web</a>,{" "}
							<Link to="/api/">API</Link> and <Link to="/container/">On-Premise</Link>.
						</p>
						<div className="text-center text-md-left">
							<a href="https://app.celantur.com/" target="_blank" className="btn btn-primary lift event-start-demo">
								Try for Free <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</a>
							<Link to="/contact/" className="btn btn-outline-primary ml-3 lift event-contact">
								Contact Us
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>

		{/* Partners */}
		<section className="py-3 mb-8 shadow-top-white">
			<Customers />
		</section>

		<section className="py-8">
			<div className="container">
				<DeploymentOptions />
			</div>
		</section>

		<section className="py-8">
			<ActionForm source="index" />
		</section>

    {/* Use Cases */}
		<section className="my-11">
			<div className="container">
				<h2 className="font-weight-bold text-center">Use Cases</h2>
				<p className="font-size-lg text-center text-muted mb-8">
					Solve privacy challenges for a wide range of commercial and industrial use cases.
				</p>
				<div className="row">
					<div className="col d-flex justify-content-around mb-5">
						<span className="link-highlight">
							<Link to="/mobile-mapping-surveying-data-anonymization/">
								Mobile Mapping
								<FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</Link>
						</span>
						<span className="link-highlight">
							<Link to="/indoor-mapping-image-blurring/">
								Indoor Mapping
								<FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</Link>
						</span>
						<span className="link-highlight">
							<Link to="/image-anonymization-terrestrial-laser-scanning/">
								Laser Scanning
								<FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</Link>
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col d-flex justify-content-around mb-5">
						<span className="link-highlight">
							<Link to="/automotive-data-anonymization/">
								Self-Driving Vehicles &amp; ADAS
								<FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</Link>
						</span>
						<span className="link-highlight">
							<Link to="/image-video-annotation-blurring/">
								Image Annotation
								<FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</Link>
						</span>
					</div>
				</div>
			</div>
		</section>

		<section className="my-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Success Stories</h2>
            <p className="font-size-lg text-gray-700">
              Read how industry-leading companies use Celantur Container to solve their privacy challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>
                <mark>NavVis</mark> provides image anonymization on a global scale
              </>
            }
            link="/success-story-navvis/"
            image={data.navvisImage.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>Fortune 500 tech company</mark> develops L4 self-driving vehicle technology in a GDPR-compliant
                manner
              </>
            }
            link="/success-story-fortune-500-self-driving/"
            image={data.traffic.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>

		<Testimonials all={true} />

		<MoreBlogPosts caption="Latest Blog Posts" />

		<WhyCelantur />

		<section className="py-8">
			<ActionForm source="index" />
		</section>

		<DataProtectionMeasures />

	</Layout>
)

export default IndexPage

export const logo = graphql`
  fragment imageProductPage on File {
    childImageSharp {
      fluid(maxWidth: 640) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    navvisImage: file(relativePath: { eq: "navvis-sample-1.jpg" }) {
      ...imageProductPage
    }
    traffic: file(relativePath: { eq: "traffic.jpg" }) {
      ...imageProductPage
    }
  }
`
